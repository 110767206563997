/* eslint no-console:0 */

import Rails from "rails-ujs";
import $ from "jquery";
import Turbolinks from "turbolinks";
import Foundation from "foundation-sites";
import * as ActiveStorage from "activestorage";

// Javascript
import "owl.carousel";

// Styles
import "owl.carousel/dist/assets/owl.carousel.css";
import "../application.scss";

// Images
import "images/facebook.svg";
import "images/instagram.svg";
import "images/linkedin.svg";
import "images/youtube.svg";
import "images/twitter.svg";
import "images/commercial.jpg";
import "images/residential.jpg";
import "images/resurfacing.jpg";
import "images/us-bowl.png";
import "images/facebook-cover.png";
import "images/SES-logo.png";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
Foundation.addToJquery($);

$(document).on("turbolinks:load", function () {
  $(document).foundation();

  setTimeout(function () {
    if ($("#slider.owl-carousel").length > 0) {
      $("#slider").owlCarousel({
        items: 3,
        autoplayHoverPause: true,
        loop: true,
        margin: 0,
        autoHeight: true,
        nav: true,
        dots: false,
        autoplay: true,
        navText: ["<span></span>", "<span></span>"],
        responsive: {
          0: {
            items: 1,
          },
          640: {
            items: 3,
          },
        },
      });
    }

    if ($("#testimonialCarousel").length) {
      $("#testimonialCarousel").owlCarousel({
        autoplay: true,
        autoplayHoverPause: true,
        dots: true,
        items: 1,
        loop: true,
        margin: 0,
      });
    }

    if ($("#serviceCarousel").length) {
      let owl = $("#serviceCarousel").owlCarousel({
        autoplay: true,
        autoplayHoverPause: true,
        items: 3,
        loop: true,
        margin: 5,
        smartSpeed: 500,
        responsive: {
          0: {
            items: 1,
          },
          640: {
            items: 3,
          },
        },
      });

      $(".service-nextbutton").click(function () {
        console.log("next");
        owl.trigger("next.owl.carousel");
      });

      $(".service-prevbutton").click(function () {
        owl.trigger("prev.owl.carousel");
      });
    }
  }, 500);
});

$(window).scroll(function () {
  if ($(this).scrollTop() > 0) {
    $(".teleno").fadeOut();
  } else {
    $(".teleno").fadeIn();
  }
});

// Project page filter function

$(document).ready(function () {
  $(".projectlink").click(function () {
    var value = $(this).attr("data-filter");
    if (value == "all") {
      $(".projectitem").show("1000");
    } else {
      $(".projectitem")
        .not("." + value)
        .hide("1000");
      $(".projectitem")
        .filter("." + value)
        .show("1000");
    }

    // Project filter add active class

    $("ul .projectlink").click(function () {
      $(this).addClass("active").siblings().removeClass("active");
    });
  });
});
